import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'next-i18next';
import Base from 'Layouts/Base';
import Article from 'Layouts/Article';
import SubMenu from 'Components/SubMenu';
import CardPerson from 'Components/CardPerson';
import LinkList from 'Components/LinkList';
import GutenbergContent from 'Components/GutenbergContent';
import Modules from 'Components/Modules';
import s from './Page.module.scss';

const Page = (props) => {
    const {subMenu, contactPersons, linkList, modules} = props;
    const showSubMenu = !_.isEmpty(subMenu) && !_.isEmpty(subMenu.items);
    const showSidebar = showSubMenu || !_.isEmpty(contactPersons) || !_.isEmpty(linkList);
    return (
        <div className={s['Page']}>
            <Article
                {...props}
                allowSidebarTopMargin={!showSubMenu}
                largeSidebar={false}
                Content={Content}
                Sidebar={showSidebar ? Sidebar : null} // Will not be hidden otherwise
            />

            <Modules {...modules} />
        </div>
    );
};

Page.propTypes = {
    gutenbergContent: PropTypes.object,
    subMenu: PropTypes.object,
    contactPersons: PropTypes.array,
    linkList: PropTypes.object,
    modules: PropTypes.object,
};

Page.defaultProps = {
    gutenbergContent: {},
    subMenu: {},
    contactPersons: [],
    linkList: {},
    modules: {},
};

const Content = ({
    gutenbergContent,
}) => {
    return (
        <div className={s['Page__GutenbergContent']}>
            <GutenbergContent {...gutenbergContent} />
        </div>
    );
};

Content.propTypes = {
    gutenbergContent: PropTypes.object,
};

Content.defaultProps = {
    gutenbergContent: {},
};

const Sidebar = ({
    subMenu,
    contactPersons,
    linkList,
}) => {
    const {t} = useTranslation();

    return (
        <div className={s['Page__Sidebar']}>
            {!_.isEmpty(subMenu) && !_.isEmpty(subMenu.items) &&
                <div className={s['Page__SubMenu']}>
                    <SubMenu {...subMenu} />
                </div>
            }

            {!_.isEmpty(contactPersons) &&
                <Fragment>
                    <h2 className="sr-only">{t('page.contactPersons')}</h2>
                    {contactPersons.map((person, index) => (
                        <div className={s['Page__ContactPerson']} key={index}>
                            <CardPerson {...person} />
                        </div>
                    ))}
                </Fragment>
            }

            {!_.isEmpty(linkList) &&
                <div className={s['Page__LinkList']}>
                    <LinkList {...linkList} />
                </div>
            }
        </div>
    );
};

Sidebar.propTypes = {
    subMenu: PropTypes.object,
    contactPersons: PropTypes.array,
    linkList: PropTypes.object,
};

Sidebar.defaultProps = {
    subMenu: {},
    contactPersons: [],
    linkList: {},
};

export default Base(Page);
