import React from 'react';
import PropTypes from 'prop-types';
import s from './PasswordProtectedForm.module.scss';

const PasswordProtectedForm = ({html}) => {
    return (
        <div className={s['PasswordProtectedForm']}>
            <div
                className={s['PasswordProtectedForm__HTML']}
                dangerouslySetInnerHTML={{__html: html}}
            />
        </div>
    );
};

PasswordProtectedForm.propTypes = {
    html: PropTypes.string,
};

PasswordProtectedForm.defaultProps = {
    html: '',
};

export default PasswordProtectedForm;
