import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import s from './LinkList.module.scss';

const LinkList = ({title, items}) => {
    if(_.isEmpty(items))
        return null;

    return (
        <div className={s['LinkList']}>
            {title &&
                <h2 className={s['LinkList__Title']}>
                    {title}
                </h2>
            }
            <ul className={s['LinkList__List']}>
                {items.map((item, i) => (
                    <Item key={i} {...item.link} />
                ))}
            </ul>
        </div>
    );
};

LinkList.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
};

LinkList.defaultProps = {
    title: '',
    items: [],
};

const Item = ({url, title, target}) => {
    return (
        <li className={s['LinkList__Item']}>
            <a className={s['LinkList__Link']} href={url} target={target}>
                <span>{title}</span>
            </a>
        </li>
    );
};

Item.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
};

Item.defaultProps = {
    url: '',
    title: '',
    target: '',
};

export default LinkList;
