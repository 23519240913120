import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import Breadcrumbs from 'Components/Breadcrumbs';
import Image from 'Components/Image';
import LockedContent from 'Components/LockedContent';
import s from './Article.module.scss';

const Article = (props) => {
    const {
        lockedContent,
        isLeft,
        isCenter,
        isLargeImage,
        breadcrumbs,
        image,
        title,
        allowSidebarTopMargin,
        largeSidebar,
        year,
        Content,
        Sidebar,
    } = props;

    const titleRef = useRef();
    const [largeTitle, setLargeTitle] = useState(true);

    const updateTitleSize = () => {
        // Don't update on resize because it will make the size change when resizing each time
        if(titleRef.current) {
            const rect = titleRef.current.getBoundingClientRect();
            setLargeTitle(rect.height <= 63);
        }
    };

    useEffect(() => {
        updateTitleSize();
    }, []);

    const sizes = isLargeImage ? [] : [
        '(min-width: 768px) 631px',
        '(min-width: 500px) 468px',
        '100vw',
    ];

    const hideContent = !_.isEmpty(lockedContent);

    const hasImage = !_.isEmpty(image) && !hideContent;
    const hasSidebar = !_.isEmpty(Sidebar) && !hideContent;
    const hasContent = !_.isEmpty(Content) && !hideContent;

    const contentMaxWidth = isLeft || hasSidebar ? 800 : 1280;
    const contentProps = {
        ...props,
        gutenbergContent: {
            ...props.gutenbergContent,
            isArticle: true,
            isArticleLeft: isLeft && !isCenter,
            isArticleRight: !isLeft && !isCenter,
            isArticleCenter: isCenter,
            contentMaxWidth: contentMaxWidth,
        },
    };

    const classes = classNames(
        [s['Article']],
        {[s['Article--IsLeft']]: isLeft && !isCenter},
        {[s['Article--IsRight']]: !isLeft && !isCenter},
        {[s['Article--IsCenter']]: isCenter},
        {[s['Article--NoBreadcrumbs']]: _.isEmpty(breadcrumbs)},
        {[s['Article--HasImage']]: hasImage},
        {[s['Article--NoImage']]: !hasImage},
        {[s['Article--LargeImage']]: hasImage && isLargeImage},
        {[s['Article--SmallImage']]: hasImage && !isLargeImage},
        {[s['Article--NoSidebar']]: !hasSidebar},
        {[s['Article--HasSidebar']]: hasSidebar},
        {[s['Article--LargeTitle']]: largeTitle},
        {[s['Article--SmallTitle']]: !largeTitle},
        {[s['Article--AllowSidebarTopMargin']]: allowSidebarTopMargin},
        {[s['Article--LargeSidebar']]: largeSidebar},
        {[s['Article--SmallSidebar']]: !largeSidebar},
        {[s[`Article--${year}`]]: year},
        // For global styling
        'Article',
        {'Article--IsLeft': isLeft && !isCenter},
        {'Article--IsRight': !isLeft && !isCenter},
    );
    return (
        <article className={classes}>
            {!_.isEmpty(breadcrumbs) &&
                <Breadcrumbs {...breadcrumbs} />
            }

            <div className={s['Article__Wrap']}>
                {hasImage &&
                    <div className={s['Article__Image']}>
                        <Image
                            {...image}
                            sizes={sizes}
                            lazyLoad={false}
                            priority={true}
                            useCover={true}
                        />
                    </div>
                }

                <div className={s['Article__Layout']}>
                    {hasSidebar && !isLeft &&
                        <aside className={s['Article__Sidebar']}>
                            <Sidebar {...props} />
                        </aside>
                    }

                    <div className={s['Article__Container']}>
                        <div className={s['Article__Content']}>
                            <h1
                                className={s['Article__Title']}
                                ref={titleRef}
                                dangerouslySetInnerHTML={{__html: sanitizeText(title)}}
                            />

                            {hasContent &&
                                <Content {...contentProps} />
                            }

                            {hideContent &&
                                <LockedContent {...props} />
                            }
                        </div>
                    </div>

                    {hasSidebar && isLeft &&
                        <aside className={s['Article__Sidebar']}>
                            <Sidebar {...props} />
                        </aside>
                    }
                </div>
            </div>
        </article>
    );
};

Article.propTypes = {
    lockedContent: PropTypes.object,
    isLeft: PropTypes.bool,
    isCenter: PropTypes.bool,
    isLargeImage: PropTypes.bool,
    breadcrumbs: PropTypes.object,
    image: PropTypes.object,
    title: PropTypes.string.isRequired,
    gutenbergContent: PropTypes.object,
    subMenu: PropTypes.object,
    linkList: PropTypes.object,
    contactPerson: PropTypes.object,
    Content: PropTypes.func,
    Sidebar: PropTypes.func,
    allowSidebarTopMargin: PropTypes.bool,
    largeSidebar: PropTypes.bool,
    year: PropTypes.string,
};

Article.defaultProps = {
    lockedContent: null,
    isLeft: false,
    isCenter: false,
    isLargeImage: false,
    breadcrumbs: {},
    image: {},
    title: '',
    gutenbergContent: {},
    subMenu: {},
    linkList: {},
    contactPerson: {},
    allowSidebarTopMargin: true,
    Content: null,
    Sidebar: null,
    largeSidebar: true,
    year: null,
};

export default Article;
