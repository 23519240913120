import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PasswordProtectedForm from 'Components/PasswordProtectedForm';
import s from './LockedContent.module.scss';

const LockedContent = ({passwordProtectedForm, lockedContent}) => {
    return (
        <div className={s['LockedContent']}>
            {!_.isEmpty(passwordProtectedForm) ? (
                <PasswordProtectedForm {...passwordProtectedForm} />
            ) : (
                <Content {...lockedContent} />
            )}
        </div>
    );
};

LockedContent.propTypes = {
    passwordProtectedForm: PropTypes.object,
    lockedContent: PropTypes.object,
};

LockedContent.defaultProps = {
    passwordProtectedForm: null,
    lockedContent: {},
};

const Content = ({title, text, link}) => {
    return (
        <div className={s['LockedContent__Content']}>
            <h2 className={s['LockedContent__Title']}>{title}</h2>
            <p className={s['LockedContent__Text']}>{text}</p>
            <Link {...link} />
        </div>
    );
};

Content.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.object,
};

Content.defaultProps = {
    title: '',
    text: '',
    link: null,
};

const Link = ({url, title}) => {
    return (
        <a className={s['LockedContent__Button']} href={url}>{title}</a>
    );
};

Link.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
};

Link.defaultProps = {
    title: '',
    url: '',
};

export default LockedContent;
